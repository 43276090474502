import React from 'react'
import { css } from '@emotion/react' 
import {mobile} from '~styles/global'

const Stylesheet = () => {
	return(
		<div css={css`
			padding: 80px; 
			${mobile}{
				padding: 20px;
			}`
		}>
			<h1>Heading 1</h1>
			<br />
			<br />
			<h2>Heading 2</h2>
			<br />
			<br />
			<h3>Heading 3</h3>
			<br />
			<br />
			<p>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam id aut sed, porro laudantium doloribus vero corporis culpa quas eaque adipisci nobis reiciendis molestias sunt tempora quam, officiis saepe excepturi!
			</p>
			<br />
			<br />
			<p className='small'>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio enim ullam voluptas excepturi accusantium culpa quod illo. Provident esse, vero voluptatem quis et earum ipsum atque, architecto aperiam quas enim.
			</p>
		</div>
	)
}

export default Stylesheet